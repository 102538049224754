// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-blog-en-tsx": () => import("./../../../src/pages/blog.en.tsx" /* webpackChunkName: "component---src-pages-blog-en-tsx" */),
  "component---src-pages-blog-pt-tsx": () => import("./../../../src/pages/blog.pt.tsx" /* webpackChunkName: "component---src-pages-blog-pt-tsx" */),
  "component---src-pages-contact-en-tsx": () => import("./../../../src/pages/contact.en.tsx" /* webpackChunkName: "component---src-pages-contact-en-tsx" */),
  "component---src-pages-contact-pt-tsx": () => import("./../../../src/pages/contact.pt.tsx" /* webpackChunkName: "component---src-pages-contact-pt-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-pt-tsx": () => import("./../../../src/pages/index.pt.tsx" /* webpackChunkName: "component---src-pages-index-pt-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blog-template.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */)
}

